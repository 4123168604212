import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcuerdoComponent } from './pages/encuesta/acuerdo/acuerdo.component';
import { EncuestaComponent } from './pages/encuesta/encuesta.component';
import { Pregunta1Component } from './pages/encuesta/preguntas/pregunta1/pregunta1.component';
import { PreguntasComponent } from './pages/encuesta/preguntas/preguntas.component';

const routes: Routes = [
    {
        path: '', component: EncuestaComponent, children: [
            { path: '', component: AcuerdoComponent },
            {
                path: 'personal', component: PreguntasComponent, children: [
                    { path: '', component: Pregunta1Component }
                ]
            },

        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
