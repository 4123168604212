import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-preguntas',
    templateUrl: './preguntas.component.html',
    styleUrls: ['./preguntas.component.scss']
})

export class PreguntasComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
