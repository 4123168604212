import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserInformation } from '../models/personal.model';
import { CookieService } from './cookie.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    options = { headers: environment.headers };

    constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

    add(user: UserInformation) {
        return this.httpClient.post(environment.baseUrl + '/acuerdo', user, this.options);
    }
}
