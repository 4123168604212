import { Component, OnInit } from '@angular/core';
import { CookieService } from './utils/services/cookie.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // providers:[CookieService]
})
export class AppComponent implements OnInit {
    title = 'encuestapage';

    constructor(private cookieService: CookieService) { }

    ngOnInit(): void {
        this.cookieService.deleteAllCookies();
    }
}
