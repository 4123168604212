import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { StepsModule } from 'primeng/steps';

//Primeng
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';

import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputSwitchModule } from 'primeng/inputswitch';

import { EncuestaComponent } from './pages/encuesta/encuesta.component';
import { AcuerdoComponent } from './pages/encuesta/acuerdo/acuerdo.component';
import { PreguntasComponent } from './pages/encuesta/preguntas/preguntas.component';
import { Pregunta1Component } from './pages/encuesta/preguntas/pregunta1/pregunta1.component';
import { CookieService } from './utils/services/cookie.service';
import { MessageService } from 'primeng/api';

@NgModule({
    declarations: [
        AppComponent,
        EncuestaComponent,
        AcuerdoComponent,
        PreguntasComponent,
        Pregunta1Component
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StepsModule,
        HttpClientModule,
        TableModule,
        CalendarModule,
        SliderModule,
        DialogModule,
        MultiSelectModule,
        ContextMenuModule,
        ButtonModule,
        ToastModule,
        InputTextModule,
        ProgressBarModule,
        DropdownModule,
        FormsModule,
        BrowserAnimationsModule,
        SidebarModule,
        MenuModule,
        CardModule,
        InputSwitchModule,
        ScrollPanelModule,
    ],
    providers: [CookieService, MessageService],
    bootstrap: [AppComponent]
})
export class AppModule { }
