<p-card class="mycard" header="Datos personales">
    <div class="bordes">
        <div (keyup.enter)="enviar()">
            <div class="grid p-fluid">
                <div class="col-12 md:col-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                        <input type="text" pInputText placeholder="Nombre completo" [(ngModel)]="personalInformation.name">
                    </div>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-6">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
                        <input type="text" pInputText placeholder="Email" [(ngModel)]="personalInformation.email">
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <p-calendar [(ngModel)]="personalInformation.age"
                        placeholder="Seleccione fecha de nacimiento" dateFormat="dd/mm/yy" ></p-calendar>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-6">
                    <p-dropdown [options]="sexos" placeholder="Seleccione su sexo"
                        [(ngModel)]="personalInformation.genero" optionLabel="name"></p-dropdown>
                </div>
                <div class="col-12 md:col-6">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-euro"></i></span>
                        <input type="text" pInputText placeholder="Ingresos" [(ngModel)]="personalInformation.ingresos">
                    </div>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-6">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-map-marker"></i></span>
                        <input type="text" pInputText placeholder="Localidad"
                            [(ngModel)]="personalInformation.localidad">
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-phone"></i></span>
                        <input type="text" pInputText placeholder="Telefono" [(ngModel)]="personalInformation.telefono">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mg-5"></div>

    <div class="botones">
        <button pButton type="button" label="Borrar" (click)="clean()" class="p-button-danger"></button>
        <button pButton type="button" label="Enviar" (click)="enviar()"></button>
    </div>
</p-card>