import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CookieService } from 'src/app/utils/services/cookie.service';

@Component({
    selector: 'app-acuerdo',
    templateUrl: './acuerdo.component.html',
    styleUrls: ['./acuerdo.component.scss']
})
export class AcuerdoComponent implements OnInit {

    accept: { emails: boolean, phone: boolean } = {
        emails: false,
        phone: false
    }

    constructor(private cookieService: CookieService, private router: Router) { }

    ngOnInit(): void {
    }

    aceptar() {
        // this.acuerdoService.aceptar({ emails: this.accept.emails, phone: this.accept.phone }).subscribe(console.log);
        this.cookieService.setCookie({ name: 'accepted', value: JSON.stringify({ emails: this.accept.emails, phone: this.accept.phone }), expireDays: 1 })
        this.router.navigate(['personal'])
    }

    cancelar() {
        document.location.assign("https://mctoledo.es")
    }

}
