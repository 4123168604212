import { Component, OnInit } from '@angular/core';
import { PersonalInformation, Terminos, UserInformation } from 'src/app/utils/models/personal.model';
import { CookieService } from 'src/app/utils/services/cookie.service';
import { UserService } from 'src/app/utils/services/user.service';

@Component({
    selector: 'app-pregunta1',
    templateUrl: './pregunta1.component.html',
    styleUrls: ['./pregunta1.component.scss']
})
export class Pregunta1Component implements OnInit {
    personalInformation: PersonalInformation;
    terminos: Terminos;
    user: UserInformation;

    sexos: { name: string, value: string }[] = [
        { name: 'Hombre', value: 'male' },
        { name: 'Mujer', value: 'female' },
        { name: 'Otros', value: 'other' }
    ];

    constructor(private userService: UserService, private cookieService: CookieService) {
        this.personalInformation = {
            name: '',
            email: '',
            age: 0,
            genero: '',
            ingresos: null,
            localidad: '',
            telefono: null
        }
        this.terminos = {
            email: false,
            phone: false
        }
        this.user = {
            personal: this.personalInformation,
            terminos: this.terminos
        }
    }

    ngOnInit() {
        this.user.terminos = JSON.parse(this.cookieService.getCookie('accepted'))
        // this.personalInformation = this.preguntasService.getTicketInformation().personalInformation;
    }

    enviar() {
        this.user.personal = this.personalInformation;

        this.userService.add(this.user).subscribe(response => {
            console.log(response)
            this.clean();
        })
    }

    clean() {
        this.personalInformation = { name: '', email: '', age: 0, genero: '', ingresos: null, localidad: '', telefono: null };
    }
}
